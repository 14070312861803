import axios from "axios";
const apiClient = axios.create({
        baseURL: 'https://back-marketing-laravela-api.rensysengineering.com',
        // baseURL: 'https://rensys-laravel.merahitechnologies.com/',    
    headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',

    }
})
export default apiClient